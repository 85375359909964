<template>
  <div class="confirmationScheduling" ref="confirmationScheduling">
    <div class="form-area" ref="form">
      <el-form
        ref="form"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="92px"
      >
        <el-form-item label="线路名称：" prop="lineId">
          <lineSelect @getData="getLineData"></lineSelect>
        </el-form-item>
        <!-- 选择时间： -->
        <el-form-item label="选择时间：" prop="time">
          <el-date-picker
            v-model="form.workDay"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="small" @click="onSearch()"
            >查询</el-button
          >
          <slot></slot>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight" width="100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="线路"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="workDay"
        label="排班日期"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="confirmUserName"
        label="确认人"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="confirmTime"
        label="确认时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="confirmStatus"
        label="确认状态"
        show-overflow-tooltip
      >
      <template slot-scope="scope">{{
          scope.row.confirmStatus==1?'已确认':''
        }}</template>
    </el-table-column>
    </el-table>
    <!-- <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination> -->
    </div>
  </div>
</template>
<script>
import { queryMsgListAPI } from "@/api/lib/bus-api.js";
import lineSelect from '@/components/lineSelect/lineSelect.vue'
import { formatDay } from "@/common/utils/index";
export default {
  components: {
    lineSelect
  },
  data () {
    return {
      tableData: [],
      tableHeight: 0,
      form: {
        // pageSize: 10,
        // currentPage: 1,
        companyId: null,
        workDay: "",
      },
      total: 0,
      downLoadStatus: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.onSearch();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.confirmationScheduling.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      // let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight = wholeHeight - 16 - formHeight - 10;
    },
    getLineData (val) {
      this.form.companyId = val;
    },
    onSearch () {
      // this.form.currentPage = 1;
      // this.form.pageSize = 10;
      this.getListByField();
    },
    getListByField () {
      let data = {...this.form};
      data.workDay = this.form.workDay ? formatDay(this.form.workDay) : null;
      queryMsgListAPI(data).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data;
        }
      })
    },
    onSizeChange () {

    },
    onCurrentChange () {

    },
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  }
}
</script>
<style lang="scss" scoped>
.confirmationScheduling {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>